<template>
    <banner></banner>
         <div class="home-feature__wrapper ">
            <div class="container">
                <div class="home-feature__card">
                    <div class="home-feature__card-img__wrapper scroll-from-left">
                        <img class="w-100" src="@/assets/img/home_1.png" alt="Shuixing design and build">
                    </div>
                    <div class="home-feature__card-text__wrapper scroll-from-right">
                        <h1 class="home-feature__card-title">Introduction</h1>
                        <p class="home-feature__card-text">SXDB is started as an in-company warehouse maintenance and upgrading works in 2015.</p>
    
                        <p class="home-feature__card-text">With the rapid development and growth in warehouse needs for logistic industry,</p>
                            
                        <p class="home-feature__card-text">SXDB has expanded and it is now specializing in providing a full range of integrated design and build services to suit customers’ requirements.</p>
                    </div>
                </div>
                <div class="home-feature__card fl-cr">
                    <div class="home-feature__card-text__wrapper scroll-from-left">
                        <h1 class="home-feature__card-title">What We Offer</h1>
                        <div>
                            <p>We are the one-stop construction solutioning for you.</p>

                            <p>With experience and expertise teams we have, we will assist you every step of the way - from architectural designs, costings, interior designs, permits, approvals and everything in between.</p>
                                
                            <p>Customer satisfaction is our top priority. We value our customers opinions and strive to incorporate their needs and ideas at each stage for delivering a best customize design and build solutions.</p>
                        </div>
                    </div>
                    <div class="home-feature__card-img__wrapper scroll-from-right">
                        <img class="w-100" src="@/assets/img/home_2.png" alt="Shuixing design and build">
                    </div>
                </div>
            </div>
           
        </div>
</template>

<script>

import {mapGetters,mapActions} from 'vuex'

import Banner from '@/components/Banner.vue'
export default {
    components: {
        Banner
    },
    data() {
        return {
            
            
        }
    },
    computed:{
		...mapGetters([              
			'isMobile',
		]),
	},
    mounted() {
        
         window.sr = ScrollReveal();
	    this.$nextTick(() => {
      		sr.reveal('.scroll-from-left', {
				origin: 'left' ,
				duration: 1000,
				easing: 'ease-out' ,
				distance: '500px',
				delay: 100,
				         
			});
			    sr.reveal('.scroll-from-right', {
                    origin: 'right' ,
                    duration: 1000,
                    easing: 'ease-out' ,
                    distance: '200px',
                    delay: 100     ,
					     
                });
                sr.reveal('.scroll-from-bottom', {    
                    duration: 1000,
                    distance: '50px',
                    delay: 100              
                });
                sr.reveal('.scroll-from-top', {    
                    origin: 'top' ,
                    duration: 1000,
                    distance: '200px',
                    delay: 100              
                });
				  sr.reveal('.scroll-from-opacity', {    
                       distance: '200px',
						opacity: 0.8,
						duration: 1000,
                });
				 sr.reveal('.scroll-from-scaleUp', {    
                       distance: '200px',
   				scale: 0.85,
                duration: 1000,
                });
      	    });
    },
    methods: {
         ...mapActions([
            "loadingEvent",
            "deviceChangeSizeEvent"
        ]),
        
    },
}
</script>

<style>

</style>